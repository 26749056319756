var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1.3.39"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
// import { Debug } from "@sentry/integrations"
import privateFilterEvent from "./src/utils/helpers/privateFilterEvent"

const dsn = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN || '__DSN__'
const environment = process.env.ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT || 'local'
const release = process.env.SENTRY_RELEASE || process.env.NEXT_PUBLIC_SENTRY_RELEASE || '0.0.0-local'
const isProduction = environment === 'production'
// const isDebugEnabled = environment === 'local' && false // change this to true to enable debug mode

const integrations = [
  Sentry.browserTracingIntegration({
    enableInp: true,
    enableLongTask: true,
  }),
  Sentry.replayCanvasIntegration({
    quality: 'medium'
  })
]

// if (isDebugEnabled) {
//   integrations.push(new Debug())
// }

Sentry.init({
  dsn,
  release,
  environment,
  tracesSampleRate: isProduction ? 0.3 : 1.0,
  autoSessionTracking: true,
  sampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: isProduction ? 0.3 : 0.5,
  integrations,
  tracePropagationTargets: [
    'http://base-api-np-service:4000',
    'https://dev.api.base.co.id',
    'https://api.base.co.id',
  ],
  beforeSend(event) {
    if (event.exception && event.exception.values) {

      const unhandledRejection = privateFilterEvent(event.exception, 'unhandledRejection')
      if (unhandledRejection) return null

      const connectionFailureError = privateFilterEvent(event.exception, 'connectionFailureError')
      const randomizeConnectionFailure = connectionFailureError && Math.random() > 0.75
      if (randomizeConnectionFailure) return null

      const filterUnnecessaryError = privateFilterEvent(event.exception, 'unnecessaryError')
      if (filterUnnecessaryError) return null

      const filterQismoConfigError = privateFilterEvent(event.exception, 'qismoError')
      if (filterQismoConfigError) return null
    }

    return event
  },
});
